window.addEventListener('load', () => {
  const yotitleNode = document.querySelector('.yotitleNotification');
  const topNode = document.querySelector('.top');
  const header = document.querySelector('.header');
  const sectionsWithColoredHeader = ['', 'web.html', 'apps.html', 'link.html'];
  const pathList = location.pathname.split('/');
  const isMenuChangesColor = sectionsWithColoredHeader.includes(pathList[pathList.length - 1]);
  const checkMenuShadow = () => {
    if (document.documentElement.scrollTop > 0) {
      topNode.classList.add('shadow');
    } else if (document.documentElement.scrollTop === 0) {
      topNode.classList.remove('shadow');
    }
  };

  checkMenuShadow();
  document.addEventListener('scroll', checkMenuShadow);

  if (!isMenuChangesColor) {
    return;
  }

  /* Header color change */
  document.onscroll = () => {
    const menuButton = topNode.querySelector('.desktop .menuButton.transparent');
    const yotitleHeight = yotitleNode.clientHeight;
    const headerHeight = header.clientHeight + yotitleHeight;
    const isBelowHeader = document.documentElement.scrollTop > headerHeight;
    const isWhite =[...topNode.classList].includes('white');

    if (isBelowHeader && !isWhite) {
      topNode.classList.add('white');

      if (menuButton) {
        menuButton.classList.add('dark');
      }
    } else if (!isBelowHeader && isWhite) {
      topNode.classList.remove('white');

      if (menuButton) {
        menuButton.classList.remove('dark');
      }
    }

  }
})